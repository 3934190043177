import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="header">
        <h1>MendoNews</h1>
        <p className="subtitle">AI-Powered Mendocino County Government Meeting Summaries</p>
      </header>
      
      <main className="main-content">
        <section className="about-section">
          <h2>About MendoNews</h2>
          <p>
            MendoNews uses artificial intelligence to make Mendocino County government more accessible 
            to everyone. We automatically generate and post concise summaries of county-level meetings, 
            including the Board of Supervisors, Planning Commission, and other key local government sessions. 
            Our AI technology transforms hours of meetings into brief, easy-to-understand video summaries 
            that help Mendocino County residents stay informed about local decisions.
          </p>
        </section>

        <section className="youtube-section">
          <h2>Watch Mendocino County Meeting Summaries</h2>
          <p>
            Subscribe to our YouTube channel for AI-generated summaries of Mendocino County
            government meetings, delivered promptly after each session:
          </p>
          <a 
            href="https://www.youtube.com/@MendoNewsDotCom" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="youtube-button"
          >
            Visit Our YouTube Channel
          </a>
        </section>

        <section className="signup-section">
          <h2>Stay Updated on County Meetings</h2>
          <p>
            Sign up to receive notifications when new Mendocino County meeting summaries 
            are available. Never miss important local government decisions again.
          </p>
          
          <div className="google-form-container">
            <iframe 
              src="https://forms.gle/6rB1FatJG5G7vH5U8"
              title="Email Signup Form"
              className="google-form"
            >
              Loading...
            </iframe>
          </div>
        </section>
      </main>

      <footer className="footer">
        <p>© {new Date().getFullYear()} MendoNews - AI-Powered Mendocino County News</p>
      </footer>
    </div>
  );
}

export default App;
